<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout column white wrap ma-2>
      <!-- CONNECTED USER'S CUSTOMER'S PROJECTS -->
      <v-flex pt-2 pb-0 px-4 d-inline-flex>
        <div>
          <v-btn v-if="project.id" flat color="primary" @click="goToProject">
            <span class="text-truncate" style="max-width:12rem">
              {{ project.name }}
            </span>
          </v-btn>
          <v-icon v-if="project.id" small>
            mdi-arrow-right
          </v-icon>
          <v-btn flat color="primary">
            <span class="text-truncate" style="max-width:12rem">
              {{ sprint.name }}
            </span>
          </v-btn>
        </div>
      </v-flex>
      <v-divider />
      <v-flex px-4>
        <h2>
          {{ sprint.name }}
        </h2>
      </v-flex>
      <v-flex>
        <v-flex right py-0>
          <v-btn v-if="isAdmin" center class="success" @click="addUserStory">
            <v-icon>mdi-plus-circle-outline</v-icon>
            <v-flex ml-2 pa-0>
              Ajouter une User Story
            </v-flex>
          </v-btn>
        </v-flex>
        <v-flex left py-0>
          <v-btn center class="quaternary" @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
            <v-flex ml-2 pa-0>
              Retour au projet
            </v-flex>
          </v-btn>
        </v-flex>
      </v-flex>
      <material-DataTableUserStory
        :items="userStories"
        :loading="loading"
        can-search
        @click:row="userStoryDetails"
        @click:edit="editUserStory"
        @click:delete="deleteUserStory"
      />
      <v-flex>
        <v-card>
          <v-card-title>
            <h5>
              <v-icon color="info">
                mdi-help-circle-outline
              </v-icon>
              Aide
            </h5>
          </v-card-title>
          <v-flex>
            <v-flex ml-2>
              Vos Sprints sont découpés en User Stories.
            </v-flex>
            <v-flex ml-2>
              <ul>
                <li>
                  <v-icon color="success">
                    <!-- progress 2 -->
                    mdi-check-circle
                  </v-icon>
                  Toutes les User Stories sont recettées.
                </li>
                <li>
                  <v-icon color="warning">
                    <!-- progress 1 -->
                    mdi-alert-circle
                  </v-icon>
                  Au moins une User Story n'est pas recettée.
                </li>
                <li>
                  <v-icon color="error">
                    <!-- progress 0 -->
                    mdi-close-circle
                  </v-icon>
                  Aucune User Story n'est recettée.
                </li>
              </ul>
            </v-flex>
          </v-flex>
        </v-card>
      </v-flex>

      <ui-DialogBox
        title="Suppression d'une US"
        msg="Voulez-vous vraiment supprimer cette US ? Attention, s'il reste des UAT et des commentaires, ils seront peut être supprimer."
        :show="userStoryToDelete > 0"
        icon-title="mdi-delete-outline"
        cancel-icon="close-circle-outline"
        ok-icon="check-circle-outline"
        @cancel="userStoryToDelete = null"
        @ok="confirmDeleteUserStory(userStoryToDelete)"
      />

      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>
<script>
import RoleMixin from '@/mixins/RoleMixin';
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'SprintDetails',

  mixins: [RoleMixin, SnackBarMixin],

  data: () => ({
    sprintId: null,
    loading: false,
    userStoryToDelete: null,
  }),

  computed: {
    project() {
      return this.$store.getters['project/getProject']() || null;
    },

    sprint() {
      return this.$store.getters['sprint/getSprint']() || null;
    },

    userStories() {
      return this.$store.getters['userStory/getSprintUserStories']() || [];
    },
  },

  created() {
    this.sprintId = this.$route.params.id;
  },

  mounted() {
    // get the sprint and the user stories associate
    this.$store.commit('app/showLoader');
    Promise.all([
      this.$store.dispatch('sprint/get_sprint_by_id', this.sprintId),
      this.$store.dispatch('userStory/get_sprint_user_stories', this.sprintId),
    ])
      .then((response) => {
        console.log('Sprint OK', response[0]);
        console.log('Sprint UserStories OK', response[1]);

        // get the project associate to the sprint
        if (
          this.project &&
          parseInt(this.project.id) !== parseInt(this.sprint.projects_id)
        ) {
          this.$store
            .dispatch('project/get_project_by_id', this.sprint.projects_id)
            .then((response) => {
              console.log('Project OK', response);
            });
        }
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text:
            'Impossible de récupérer les user stories du sprint : ' +
            error.message,
        });
      })
      .finally(() => {
        this.$store.commit('app/showLoader');
        this.$store.commit('app/resetDashboardIds');
        this.$store.commit('app/setDashboardIds', {
          item: 'project',
          id: this.sprint.projects_id,
        });
        this.$store.commit('app/setDashboardIds', {
          item: 'sprint',
          id: this.sprint.id,
        });
      });
  },

  methods: {
    goBack() {
      this.$router.push({ path: `/project/${this.sprint.projects_id}` });
    },

    goToProject() {
      this.$router.push({ path: `/project/${this.sprint.projects_id}` });
    },

    userStoryDetails(id) {
      console.log('userStoryDetails', id);
      this.$router.push({ path: `/userStory/${id}` });
    },

    addUserStory() {
      console.log('addUserStory');
      this.$router.push({ path: `/userStory/create` });
    },

    editUserStory(id) {
      console.log('editUserStory', id);
      this.$router.push({ path: `/userStory/${id}/edit` });
    },

    deleteUserStory(id) {
      console.log('deleteUserStory', id);
      this.userStoryToDelete = id;
    },
    confirmDeleteUserStory(id) {
      console.log('confirmDeleteUserStory', id);
      this.$store
        .dispatch('userStory/delete_user_stories_by_id', id)
        .then(() => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text: 'User story supprimé avec succès',
          });
        })
        .catch((error) => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Impossible de supprimer la User Story : ' + error.message,
          });
        })
        .finally(() => {
          this.userStoryToDelete = null;
        });
    },
  },
};
</script>

<style></style>
